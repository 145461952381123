@import url('https://fonts.googleapis.com/css2?family=Itim&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

.navbar-brand {
  font-family: 'Itim', cursive;
  font-size: 2em !important;
}

.navbar-links {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}
